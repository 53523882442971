
import {
  IonCheckbox,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonHeader,
  IonLabel,
  IonRadio,
  IonToggle,
  IonFab,
  IonFabButton,
  IonFabList,
  IonModal,
  IonPage,
  IonRow,
  IonBadge,
  IonToolbar,
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonCol,
  IonTitle,
  IonButtons,
  menuController,
  loadingController,
  IonSkeletonText,
  IonThumbnail
} from '@ionic/vue'

import axios from "axios";

import * as allIcons from 'ionicons/icons'

import { Options, Vue } from 'vue-class-component'

import fintailapi from '../api'

import { useStore } from 'vuex'

import {Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import 'swiper/swiper-bundle.css'

import { FreeMode } from "swiper";

import { Drivers, Storage } from '@ionic/storage'

import { Geolocation } from '@capacitor/geolocation'

import ChangeEndereco from '../components/ChangeEndereco.vue'

import Produto from '../components/Produto.vue'

import { StatusBar, Style } from '@capacitor/status-bar';

import FooterMenu from '../components/FooterMenu.vue'

import Searchbar from '../components/Searchbar.vue';
import { consoleSandbox } from '@sentry/utils'

Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({
  components: { ChangeEndereco, Produto, IonModal, Swiper, SwiperSlide, IonLabel, IonInput,  IonFab,  IonFabButton,  IonFabList, IonItem, IonPage, IonHeader, IonContent, IonGrid, IonFooter, IonIcon, IonCol, IonBadge, IonRow, IonToolbar, IonTitle, IonButtons, IonButton,IonSkeletonText,
  IonThumbnail, FooterMenu, Searchbar },
  computed: {
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    }
  },
  setup() {
    return {
      modules: [FreeMode],
    };
  }
})

export default class Mercado extends Vue {

  
  storage = new Storage({
    name: '__jauserve',
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });

  isOpen = false;
  isOpenSemEntrega = false;
  isOpenLocation   = false;
  isOpenCategorias = false;


  showIngCategorias = true;

  showIngCategoriasCancel : [ any ] = [""]

  


  icones_categorias : { [key: string]: string } = {
      "EC01" : '/assets/img/categorias/EC01.svg',
      "EC02":  '/assets/img/categorias/EC02.svg',
      "EC03":  '/assets/img/categorias/EC03.svg',
      "EC04": '/assets/img/categorias/EC04.svg',
      "EC05":  '/assets/img/categorias/EC05.svg',
      "EC06":  '/assets/img/categorias/EC06.svg',
      "EC07":  '/assets/img/categorias/EC07.svg',
      "EC08":  '/assets/img/categorias/EC08.svg',
      "EC09":  '/assets/img/categorias/EC09.svg',
      "EC10":  '/assets/img/categorias/EC10.svg',
      "EC11":  '/assets/img/categorias/EC11.svg',
      "EC12": '/assets/img/categorias/EC12.svg',
      "EC13":  '/assets/img/categorias/EC13.svg',
      "EC14":  '/assets/img/categorias/EC14.svg',
      "EC15":  '/assets/img/categorias/EC15.svg',
      "EC16":  '/assets/img/categorias/EC16.svg',
      "Empresas_Responsaveis":  '/assets/img/categorias/Empresas_Responsaveis.svg'
  }

  texto_pesquisa = "";

  modo_entrega = false;
  descontoIcon = "/assets/icon/assets0005.svg";

  getLocalicao () {
    Geolocation.getCurrentPosition().then((resp) => {
      this.getLoja({ latitude: resp.coords.latitude, longitude: resp.coords.longitude });
    }).catch((error) => {
       console.log('Error getting location', error)
    })
  }

  autoComplete () {

    fintailapi
    .get('/endereco/'+this.enderecoProcurar)
    .then((response:any) => {
      const data = response.data
      this.searchEnderecos = data.predictions
    })

  }

  goToSearch(n:any){

    this.searchEnderecos = []
    this.categorias = []

    this.store.state.user.pesquisa = n
    this.$router.push("/pesquisar")

  }



  selectAdress(endereco:any){

    this.getLoja(endereco);

  }

  getLoja (endereco:object) {

    fintailapi
    .post('/endereco/lojas/', endereco)
    .then((response:any) => {

      const data = response.data

      this.modo_entrega = data.entrega;

      if(data.entrega){

        this.storage.set('location', data)
        this.currentEnderecos = data

        this.store.state.user.geolocation = data;

        this.setOpen(false);

      }else{

        this.storage.set('location', data)

        this.currentEnderecos = data
        this.isOpenSemEntrega = true;

        this.store.state.user.geolocation = data;

      }

      fintailapi.defaults.headers.common['Store'] = data.codigo_loja;

      this.getCategorias()

    })

  }

  goRetirada(){

    this.isOpenSemEntrega = false
    this.isOpenLocation = false

  }

  setOpen (isOpen: boolean) {

    this.categoriasModal = []
    this.isOpen = false;
    this.isOpenLocation   = false;
    this.isOpenCategorias = false;
    this.isOpenSemEntrega = false;

  }

  changeEndereco () {

    this.store.state.user.info.isOpenLocation = true

  }

  openMenu () {
    menuController.open('app-menu')
  }

  showLoading = async () => {
    const loading = await loadingController.create({
      message: '',
      duration: 4000
    })

    loading.present()
  }

  user = { name: 'test' }

  banners = [
    { ordem: 0, url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAFNAQMAAADM3Q90AAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMjkvMjIAisaqAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAACxJREFUeJztwTEBAAAAwqD1T20ND6AAAAAAAAAAAAAAAAAAAAAAAAAAAODXAFNAAAGZLaipAAAAAElFTkSuQmCC' }
  ];

  default = [
        { ordem: 0, id_jau: 'NULL', name:" ", produtos:[{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }] },
        { ordem: 0, id_jau: 'NULL', name:" ", produtos:[{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }]  },
        { ordem: 0, id_jau: 'NULL', name:" ", produtos:[{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }]  },
        { ordem: 0, id_jau: 'NULL', name:" ", produtos:[{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }]  },
        { ordem: 0, id_jau: 'NULL', name:" ", produtos:[{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }]  },
  ];

default_produtos = [{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }]


  categorias = [{ ordem: 0, id_jau: 'NULL', name:" ", produtos:[{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }] }]
  categoriasModal = [ { ordem: 0, id_jau: 'NULL', name:" ", produtos:[{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' },{name:"", price:0, image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=' }] }]

  modules = [FreeMode];
  //ICONES
  iconRight = allIcons.chevronForward
  iconDow   = allIcons.chevronDown
  iconVoltar   = allIcons.chevronBack

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove
  iconLayer = allIcons.layers
  iconMenu = allIcons.list

  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox
  icon_churras = allIcons.restaurant
  icon_recarga = allIcons.phonePortrait
  icon_qr = allIcons.qrCode
  icon_pesquisa = allIcons.search
  icon_home = allIcons.home
  icon_cart = allIcons.cart
  icon_promo = allIcons.pricetag
  icon_map = allIcons.map
  icon_location = allIcons.locationOutline
  iconLocationF = allIcons.location
  iconInicio    = allIcons.homeOutline
  iconSecoes    = allIcons.gridOutline
  iconPromocoes = allIcons.bagHandleOutline
  iconLojas     = allIcons.storefrontOutline



  enderecoProcurar = ''
  searchEnderecos = []

  currentEnderecos = { cep: '', endereco: '', loja: '', latitude: 0, longitude: 0 }

  store = useStore();



  onlyTopBanner (banners : any) {
    if(!banners)return []
    return banners.filter( (banner:any) =>{  return banner.principal;  })
  }

  onlySubBanner (banners : any) {
    if(!banners)return []
    return banners.filter( (banner:any) =>{  return !banner.principal;  })
  }

  onlyCategoria (categoria : any) {
    if(!categoria)return []
    return this.categorias.filter( (cat:any) =>{  return !cat.frontend;  })
  }

  getIconeCat (categoria : any) {

    let img = this.icones_categorias[categoria.id_jau];
    if(!img){
      img ='data:image/svg+xml;base64,'+ window.btoa('<svg xmlns=\'http://www.w3.org/2000/svg\' height="15" width="12"><text x="1" y="12">'+categoria.name.charAt(0)+'</text></svg>')
    }
    return  img 
  }
  

  getCategoriaUnica =async (categoria:any, x:any) => {
    
    fintailapi
    .get('/mercado/'+(categoria.frontend?'frontend/':'')+categoria.id,{  signal: this.showIngCategoriasCancel[x].signal })
    .then((response:any) => {

      const data_prod = response.data
      if (data_prod.success) {
        let current_categoria = data_prod.categorias[0] 

        if(current_categoria){

          this.categorias[x].produtos =  current_categoria.produtos.filter((xx: any) => !!xx).map((produto: any) => {

                                                  if(produto != null){

                                                    let p = this.store.getters.productIncart(produto.id);

                                                    if(p){

                                                      produto.incart = true;
                                                      produto.qtd    = p.quantity;

                                                    }
                                                  }else{
                                                    return  {}
                                                  }
                                                  return produto
                                            });
        }else{
          this.categorias[x].produtos = []
        }

      }

    }).catch((error) => {

      setTimeout(() => { 
        this.getCategoriaUnica(categoria, x);
        if(this.categorias[x])this.categorias[x].produtos = []
      }, 1000)
      
    })


  }


  getCategorias = async () => {

    fintailapi
    .get('/mercado')
    .then((response:any) => {
      const data = response.data

      if (data.success) {

        if(data.banners){        
          this.banners = data.banners
        }

        this.categorias = data.categorias.map((categoria: any, x: any) => {

          categoria.produtos = this.default_produtos
          
          setTimeout(() => {

            if(!this.showIngCategorias)return categoria;

            const controller = new AbortController();
            this.showIngCategoriasCancel[x] = controller

            this.getCategoriaUnica(categoria, x);
          
          }, x*200)

          return categoria;

        })
      }

    }).catch((error) => {
      setTimeout(() => {
        this.getCategorias()
      }, 1000)
    })

  }

  showCategoria (categoria : string) {

    this.categoriasModal = this.default
    this.isOpenCategorias = true;

    fintailapi
    .get('/categoria/'+categoria)
    .then((response:any) => {
      const data = response.data
      if (data.success) {
        this.categoriasModal = data.categorias
      }
    })

    //this.$router.push("/categoria/"+categoria)
    //this.$router.push({ name: 'Categoria', params: {"categoria": categoria }})
  }

  navigate (url : string) {
    this.$router.push(url)
  }

  created () {
    this.categorias = this.default
    this.storage.create();
  }


  stopCategorias() {
  
    this.searchEnderecos = []
    this.categorias = []

    this.showIngCategorias=false

    for (const cancel of this.showIngCategoriasCancel) {
      if(cancel)cancel.abort()
    }

  }

  goBackEndereco() {

    this.storage.get('location').then((location) => {

      if (!location || !location.cep){
        this.navigate('/')
      }

    });

  }

  getEnderecos () {
    (async () => {

      const location = await this.storage.get('location')

      if (!location){
        this.changeEndereco ()
      }else{

        this.currentEnderecos = location
        this.store.state.user.geolocation = location;

        if(this.store.state.order)this.store.state.order.final = false;

        this.modo_entrega = location.entrega;

        fintailapi.defaults.headers.common['Store'] = location.codigo_loja;

        this.showIngCategorias=true
        this.getCategorias()

      }

      const banners = await this.storage.get('banners')

      this.banners = banners

    })()
  }

  processaBanner(banner:any) {

    if(banner.type_redirect == "LINK EXTERNO"){




        if(banner.categoria){

          const regex = /EC/;

          if (regex.test(banner.categoria)) {	
            this.navigate("/categoria/"+banner.categoria)
          }else{
            this.navigate('/subcategoria/'+banner.categoria)
          }
        
        }else  if(banner.produto){
          this.navigate('/produto/'+banner.produto)
        }else{
          window.open(banner.url_redirect, '_blank', 'noreferrer');
        }

        return;

    }
  }


  processaVerMais(categoria:any) {

        if(categoria.frontend){

          this.navigate('/subcategoria/'+categoria.id_jau)

        }else{

          const regex = /EC/;

          if (regex.test(categoria.id_jau)) {	
            this.navigate("/categoria/"+categoria.id_jau)
          }else{
            this.navigate('/subcategoria/'+categoria.id_jau)
          }

        }
  
  }


  mounted () {
    //this.$nextTick(function () {
      this.getEnderecos ()
   // })
  }

  beforeRouteEnter(to:any, from:any, next:any) {

    next();

  }

  beforeRouteLeave(to:any, from:any, next:any){

    this.searchEnderecos = []
    this.categorias = []

    this.showIngCategorias=false

    for (const cancel of this.showIngCategoriasCancel) {
      if(cancel)cancel.abort()
    }

    next();

  }

  beforeRouteUpdate(to:any, from:any, next:any) {
    next();
  }

}

