

import {
  IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  IonTextarea,
  IonApp,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonMenuToggle,
  IonFooter,
  IonButton,
  IonButtons,
  IonModal,
  IonGrid,
  IonAvatar,
  IonListHeader,
  IonItem,
  IonCol, IonBadge, IonRow,IonInput, alertController } from "@ionic/vue";


  import { defineComponent } from 'vue';
  import { locationOutline, chevronDown, chevronForward, chevronBack, helpCircle, checkmark, close, search } from 'ionicons/icons';

  import fintailapi from '../api'

  export default defineComponent({
  name: "Search",
  components: { IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  IonApp,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonTextarea,
  IonMenuToggle,
  IonFooter,
  IonGrid,
  IonAvatar,
  IonListHeader,
  IonButton,
  IonButtons,
  IonModal,
  IonItem,
  IonCol, IonBadge, IonRow,IonInput, alertController },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  emits: [ "update:modelValue", "onChange", "onSearch" ],
  methods: {
    gotoSelect(texto:any){

      this.$emit("update:modelValue", texto)
      this.$emit("onSearch", texto)

    },
    gotoPesquisar(){

      if( !this.data.search ){
        this.$emit("update:modelValue", this.texto_pesquisa)
        this.$emit("onChange", this.texto_pesquisa)
      }else{
        this.$emit("update:modelValue", this.texto_pesquisa)
        this.$emit("onSearch", this.texto_pesquisa)
      }
   },
    voltar(){

      if( !this.data.search ){
        clearTimeout(this.ultima_pesquisa_auto);

        this.ultima_pesquisa_auto = setTimeout(()=>{
          this.$emit("update:modelValue", this.texto_pesquisa)
          this.$emit("onChange", this.texto_pesquisa)
          this.typing=false
        }, 800)

      }else{

        clearTimeout(this.ultima_pesquisa_auto);

        this.ultima_pesquisa_auto = setTimeout(()=>{

            fintailapi
            .get('/produtos/sugestoes/'+this.texto_pesquisa)
            .then((response:any) => {
              const data = response.data
              this.typing=false
              this.autoCompleteProdutos = data.termos
            })

        }, 800)


      }

    }

  },
  data() {
    return {
      texto_pesquisa : "",
      isOpenLocation:false,
      isOpenGetNumber:false,
      isEnviando:false,
      typing:false,
      safeTop:0,
      autoCompleteProdutos:[],
      ultima_pesquisa_auto : 0,
      enderecoProcurar : "",
      searchEnderecos:[],
      currentEnderecos:{ first_name : "", last_name : ""},
      selectedEnderecos:[],
      isLast: false,
      locationOutline,chevronDown,chevronForward, helpCircle, checkmark, close,search,chevronBack,
    }
  },
  setup() {
    return {
      texto_pesquisa: ""
    };
  },
  mounted() {
  }
});
