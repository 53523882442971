import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, { size: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { lines: "none" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  enterkeyhint: "search",
                  type: "search",
                  onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.texto_pesquisa=$event.target.value)),
                  onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.typing=true)),
                  "clear-input": true,
                  onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.voltar())),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.voltar())),
                  onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.gotoPesquisar()), ["enter"])),
                  modelValue: _ctx.texto_pesquisa,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.texto_pesquisa) = $event)),
                  class: "item_pesquisa_local",
                  placeholder: "Buscar produtos..."
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          color: "danger",
                          size: "medium",
                          slot: "end",
                          icon: _ctx.search
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.typing)
      ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_spinner, { name: "circular" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.autoCompleteProdutos.length > 0)
      ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autoCompleteProdutos, (produto, index) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: index,
                onClick: ($event: any) => (_ctx.gotoSelect(produto))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(produto), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}